import { services } from '../../../../api/feathers';

export const GET_DEVICES_LOGS = 'GET_DEVICES_LOGS';

export function getDevicesLogsList(id, query = {}) {
  console.log('query', query);
  return (dispatch) =>
    services.elasticServices.get(id, query).then((response) => {
      dispatch({
        type: GET_DEVICES_LOGS,
        payload: response.hits.hits,
      });
    });
}
