import { services } from '../../../../api/feathers';

export const GET_DEVICE_REGISTRATIONS = 'GET_DEVICE_REGISTRATIONS';
export const GET_DEVICE_REGISTRATION = 'GET_DEVICE_REGISTRATION';
export const ADD_DEVICE_REGISTRATIONS = 'ADD_DEVICE_REGISTRATIONS';
export const UPDATE_DEVICE_REGISTRATIONS = 'UPDATE_DEVICE_REGISTRATIONS';
export const REMOVE_DEVICE_REGISTRATIONS = 'REMOVE_DEVICE_REGISTRATIONS';

export function getDeviceRegistrations(params) {
  return (dispatch) =>
    services.deviceRegistrations.find({ query: params }).then((response) =>
      dispatch({
        type: GET_DEVICE_REGISTRATIONS,
        payload: response.data,
      })
    );
}

export function getDeviceRegistration(id) {
  return (dispatch) =>
    services.deviceRegistrations.get(id).then((response) =>
      dispatch({
        type: GET_DEVICE_REGISTRATION,
        payload: response,
      })
    );
}

export function addDeviceRegistrations(deviceRegistration) {
  return (dispatch) =>
    services.deviceRegistrations
      .create(deviceRegistration)
      .then((response) =>
        dispatch({
          type: ADD_DEVICE_REGISTRATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceRegistrations()));
}

export function updateDeviceRegistrations(id, deviceRegistration) {
  return (dispatch) =>
    services.deviceRegistrations
      .patch(id, deviceRegistration)
      .then((response) =>
        dispatch({
          type: UPDATE_DEVICE_REGISTRATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceRegistrations()));
}

export function removeDeviceRegistrations(id) {
  return (dispatch) =>
    services.deviceRegistrations
      .remove(id)
      .then((response) =>
        dispatch({
          type: REMOVE_DEVICE_REGISTRATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceRegistrations()));
}
