/* eslint-disable import/prefer-default-export */
// import React, {Component} from 'react';
import RecoverPassword from './RecoverPassword';
// import {authRoles} from 'auth';

export const RecoverPasswordConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path     : '/recoverPassword/:userId/:code',
			exact    : true,
			component: RecoverPassword
		}
	]
};

