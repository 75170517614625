import { services } from '../../../../api/feathers';

export const GET_FIRMWARE_UPDATES = 'GET_FIRMWARE_UPDATES';
export const GET_FIRMWARE_UPDATE = 'GET_FIRMWARE_UPDATE';
export const ADD_FIRMWARE_UPDATES = 'ADD_FIRMWARE_UPDATES';
export const UPDATE_FIRMWARE_UPDATES = 'UPDATE_FIRMWARE_UPDATES';

export function getFirmwareUpdates(query = {}) {
  return (dispatch) =>
    services.firmwareUpdates.find(query).then((response) =>
      dispatch({
        type: GET_FIRMWARE_UPDATES,
        payload: response.data,
      })
    );
}

export function getFirmwareUpdate(id) {
  return (dispatch) =>
    services.firmwareUpdates.get(id).then((response) =>
      dispatch({
        type: GET_FIRMWARE_UPDATE,
        payload: response,
      })
    );
}

export function addFirmwareUpdates(firmwareUpdate) {
  return (dispatch) =>
    services.firmwareUpdates
      .create(firmwareUpdate)
      .then((response) =>
        dispatch({
          type: ADD_FIRMWARE_UPDATES,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareUpdates()));
}

export function updateFirmwareUpdates(id, firmwareUpdate) {
  return (dispatch) =>
    services.firmwareUpdates
      .patch(id, firmwareUpdate)
      .then((response) =>
        dispatch({
          type: UPDATE_FIRMWARE_UPDATES,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareUpdates()));
}
