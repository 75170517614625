import * as Action from '../actions/usageLogs.actions';

const initialState = [];

const usageLogs = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_USAGE_LOGS:
      return action.payload;

    case Action.UPDATE_USAGE_LOGS: {
      const newLogs = action.payload
        .map((log) => {
          const repeatedLog = state.some(
            (stateLog) => stateLog.uuid === log.uuid
          );

          if (repeatedLog) {
            return null;
          }
          return log;
        })
        .filter((log) => log !== null);

      return [...newLogs, ...state];
    }

    default:
      return state;
  }
};

export default usageLogs;
