/* eslint-disable default-param-last */
import * as Actions from '../actions/deviceRegistrations.actions';

const initialState = [];

const deviceRegistrations = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DEVICE_REGISTRATIONS:
      return action.payload;

    case Actions.ADD_DEVICE_REGISTRATIONS:
      return [...state, action.payload];

    case Actions.UPDATE_DEVICE_REGISTRATIONS:
    case Actions.GET_DEVICE_REGISTRATION: {
      const index = state.findIndex(
        (deviceRegistration) => deviceRegistration.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_DEVICE_REGISTRATIONS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default deviceRegistrations;
