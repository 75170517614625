/* eslint-disable default-param-last */
import * as Actions from '../actions/devicesLogs.actions';

const initialState = [];

const deviceLogs = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DEVICES_LOGS:
      return action.payload;

    default:
      return state;
  }
};

export default deviceLogs;
