import { services } from '../../../../api/feathers';

export const GET_SHORTCUTS = 'GET_SHORTCUTS';
export const GET_SHORTCUT = 'GET_SHORTCUT';
export const ADD_SHORTCUTS = 'ADD_SHORTCUTS';
export const UPDATE_SHORTCUTS = 'UPDATE_SHORTCUTS';
export const REMOVE_SHORTCUTS = 'REMOVE_SHORTCUTS';

export function getShortcuts(params = {}) {
  return (dispatch) =>
    services.shortcuts.find({ query: params }).then((response) =>
      dispatch({
        type: GET_SHORTCUTS,
        payload: response.data,
      })
    );
}

export function getShortcut(id) {
  return (dispatch) =>
    services.shortcuts.get(id).then((response) =>
      dispatch({
        type: GET_SHORTCUT,
        payload: response,
      })
    );
}

export function addShortcuts(shortcut) {
  return (dispatch) =>
    services.shortcuts.create(shortcut).then((response) =>
      dispatch({
        type: ADD_SHORTCUTS,
        payload: response,
      })
    );
}

export function updateShortcuts(id, shortcut) {
  return (dispatch) =>
    services.shortcuts.patch(id, shortcut).then((response) =>
      dispatch({
        type: UPDATE_SHORTCUTS,
        payload: response,
      })
    );
}

export function removeShortcuts(id) {
  return (dispatch) =>
    services.shortcuts.remove(id).then((response) =>
      dispatch({
        type: REMOVE_SHORTCUTS,
        payload: response,
      })
    );
}
