import * as Actions from '../actions/lockControls.actions';

const initialState = [];

const lockControls = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_LOCK_CONTROLS: {
      return action.payload;
    }
    case Actions.ADD_LOCK_CONTROLS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_LOCK_CONTROLS:
    case Actions.GET_LOCK_CONTROL: {
      const index = state.findIndex(
        (lockControl) => lockControl.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_LOCK_CONTROLS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default lockControls;
