/* eslint-disable import/no-extraneous-dependencies */
import 'babel-polyfill';
import 'typeface-muli';
import React from 'react';
import ReactDOM from 'react-dom';
import './react-table-defaults';
import './styles/index.css';
// import './fake-db/fake-db';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import jssExtend from 'jss-extend';
import { FuseLayout, FuseTheme, FuseAuthorization } from './@fuse';
import { unregister } from './registerServiceWorker';
import { routes } from './fuse-configs/fuseRoutesConfig';
import MainToolbar from './main/MainToolbar';
import MainNavbarContent from './main/MainNavbarContent';
import MainNavbarHeader from './main/MainNavbarHeader';
import history from './history';
import store from './store';
import { Auth } from './auth';

unregister();

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <Router history={history}>
        <Auth>
          <FuseAuthorization routes={routes}>
            <FuseTheme>
              <FuseLayout
                routes={routes}
                toolbar={<MainToolbar />}
                navbarHeader={<MainNavbarHeader />}
                navbarContent={<MainNavbarContent />}
              />
            </FuseTheme>
          </FuseAuthorization>
        </Auth>
      </Router>
    </Provider>
  </JssProvider>,
  document.getElementById('root')
);
