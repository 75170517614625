import * as Actions from '../actions/scenarios.actions';

const initialState = [];

const scenarios = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_SCENARIOS: {
      return action.payload;
    }
    case Actions.ADD_SCENARIOS: {
      return [...state, action.payload];
    }
    case Actions.ADD_SCENARIOS_AUTO: {
      return [...state, ...action.payload];
    }
    case Actions.UPDATE_SCENARIOS:
    case Actions.GET_SCENARIO: {
      const index = state.findIndex(
        (account) => account.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_SCENARIOS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default scenarios;
