import { services } from '../../../../api/feathers';

export const GET_SCENARIOS_SCHEDULES = 'GET_SCENARIOS_SCHEDULES';
export const GET_SCENARIO_SCHEDULES = 'GET_SCENARIO_SCHEDULES';
export const ADD_SCENARIOS_SCHEDULES = 'ADD_SCENARIOS_SCHEDULES';
export const UPDATE_SCENARIOS_SCHEDULES = 'UPDATE_SCENARIOS_SCHEDULES';
export const REMOVE_SCENARIOS_SCHEDULES = 'REMOVE_SCENARIOS_SCHEDULES';

export function getScenariosSchedules(scenarioId) {
  return (dispatch) =>
    services.scenariosSchedules
      .find({ query: { scenarioId } })
      .then((response) =>
        dispatch({
          type: GET_SCENARIOS_SCHEDULES,
          payload: response.data,
        })
      );
}

export function getScenarioSchedules(id) {
  return (dispatch) =>
    services.scenariosSchedules.get(id).then((response) =>
      dispatch({
        type: GET_SCENARIO_SCHEDULES,
        payload: response,
      })
    );
}

export function addScenariosSchedules(account) {
  return (dispatch) =>
    services.scenariosSchedules
      .create(account)
      .then((response) =>
        dispatch({
          type: ADD_SCENARIOS_SCHEDULES,
          payload: response,
        })
      )
      .then(() => dispatch(getScenariosSchedules()));
}

export function updateScenariosSchedules(id, account) {
  return (dispatch) =>
    services.scenariosSchedules
      .patch(id, account)
      .then((response) =>
        dispatch({
          type: UPDATE_SCENARIOS_SCHEDULES,
          payload: response,
        })
      )
      .then(() => dispatch(getScenariosSchedules()));
}

export function removeScenariosSchedules(id) {
  return (dispatch) =>
    services.scenariosSchedules.remove(id).then((response) =>
      dispatch({
        type: REMOVE_SCENARIOS_SCHEDULES,
        payload: response,
      })
    );
}
