import * as Actions from '../actions/firmwareVersions.actions';

const initialState = [];

const firmwareVersions = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_FIRMWARE_VERSIONS: {
      return action.payload;
    }
    case Actions.ADD_FIRMWARE_VERSIONS: {
      return [...state, action.payload]
    }
    case Actions.UPDATE_FIRMWARE_VERSIONS:
    case Actions.GET_FIRMWARE_VERSION: {
      const index = state.findIndex(
        (firmwareVersion) => firmwareVersion.id === action.payload.id
      );
      const newState = state
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_FIRMWARE_VERSIONS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }

    default:
      return state;
  }
};

export default firmwareVersions;
