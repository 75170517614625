import * as Actions from '../actions/users.actions';

const initialState = [];

const users = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_USERS: {
      return action.payload;
    }
    case Actions.ADD_USERS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_USERS:
    case Actions.GET_USER: {
      const index = state.findIndex((user) => user.id === action.payload.id);
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_USERS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default users;
