import { services } from '../../../../api/feathers';

export const GET_CENTRAL_LOGINS = 'GET_CENTRAL_LOGINS';
export function getCentralLogins(query = {}) {
  return (dispatch) =>
    services.centralLogins.find(query).then((response) =>
      dispatch({
        type: GET_CENTRAL_LOGINS,
        payload: response.data,
      })
    );
}
