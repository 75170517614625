/* eslint-disable import/no-cycle */
import history from '../../../history';
import { setDefaultSettings } from '../../../store/actions/fuse';
import { FuseDefaultSettings } from '../../../@fuse';
import { loginOut } from './login.actions';
import { app } from '../../../main/api/feathers';

export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export function setUserData(user) {
  return (dispatch) => {
    // dispatch(setDefaultSettings(user.data.settings));
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

export function getUserData() {
  return (dispatch) =>
    app.authenticate().then((response) =>
      dispatch({
        type: SET_USER_DATA,
        payload: response.user,
      })
    );
}

export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

export function logoutUser() {
  return (dispatch, getState) => {
    dispatch(loginOut());
    // eslint-disable-next-line no-unused-vars
    const { user } = getState().auth;
    console.log('user:', user);

    history.push({
      pathname: '/login',
    });

    app.logout();

    dispatch(setDefaultSettings(FuseDefaultSettings));
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
