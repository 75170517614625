import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { IconButton, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quickPanelActions from './quickPanel/store/actions';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  seperator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
});

function MainToolbar({ classes, toggleQuickPanel }) {
  return (
    <div className={classNames(classes.root, 'flex flex-row')}>
      <div className="flex flex-1 px-24">
        <Typography>SMARTEK LIFE</Typography>
      </div>

      <div className="flex">
        {/* <div className={classes.seperator} /> */}

        <IconButton
          className="w-64 h-64"
          onClick={() => toggleQuickPanel(true)}
        >
          {/* <Icon>format_list_bulleted</Icon> */}
        </IconButton>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: quickPanelActions.toggleQuickPanel,
    },
    dispatch
  );
}

export default withStyles(styles, { withTheme: true })(
  connect(null, mapDispatchToProps)(MainToolbar)
);
