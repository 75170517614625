/* eslint-disable default-param-last */
import { LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_OUT } from '../actions';

const initialState = {
  verifyed: false,
};

// eslint-disable-next-line func-names
const login = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        verifyed: true,
        error: null,
        success: true,
      };
    }
    case LOGIN_ERROR: {
      return {
        verifyed: true,
        success: false,
        error: action.payload,
      };
    }
    case LOGIN_OUT: {
      return {
        verifyed: true,
        success: false,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default login;
