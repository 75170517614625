import { services } from '../../../../api/feathers';

export const GET_SWITCH_CONTROLS = 'GET_SWITCH_CONTROLS';
export const GET_SWITCH_CONTROL = 'GET_SWITCH_CONTROL';
export const ADD_SWITCH_CONTROLS = 'ADD_SWITCH_CONTROLS';
export const UPDATE_SWITCH_CONTROLS = 'UPDATE_SWITCH_CONTROLS';
export const REMOVE_SWITCH_CONTROLS = 'REMOVE_SWITCH_CONTROLS';

export function getSwitchControls(deviceId) {
  return (dispatch) =>
    services.switchControls
      .find({
        query: {
          deviceId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_SWITCH_CONTROLS,
          payload: response.data,
        })
      );
}

export function getSwitchControl(id, query = {}) {
  return (dispatch) =>
    services.switchControls.get(id, query).then((response) =>
      dispatch({
        type: GET_SWITCH_CONTROL,
        payload: response,
      })
    );
}

export function addSwitchControls(switchControl) {
  return (dispatch) =>
    services.switchControls
      .create(switchControl)
      .then((response) =>
        dispatch({
          type: ADD_SWITCH_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getSwitchControls(switchControl.deviceId)));
}

export function updateSwitchControls(id, switchControl) {
  return (dispatch) =>
    services.switchControls.patch(id, switchControl).then((response) =>
      dispatch({
        type: UPDATE_SWITCH_CONTROLS,
        payload: response,
      })
    );
}

export function removeSwitchControls(id) {
  return (dispatch) =>
    services.switchControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_SWITCH_CONTROLS,
        payload: response,
      })
    );
}
