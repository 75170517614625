import { services } from '../../../../api/feathers';

export const GET_ACCOUNTS_USERS = 'GET_ACCOUNTS_USERS';
export const GET_ACCOUNT_USER = 'GET_ACCOUNT_USER';
export const ADD_ACCOUNTS_USERS = 'ADD_ACCOUNTS_USERS';
export const UPDATE_ACCOUNTS_USERS = 'UPDATE_ACCOUNTS_USERS';
export const REMOVE_ACCOUNTS_USERS = 'REMOVE_ACCOUNTS_USERS';

export function getAccountsUsers(params) {
  return (dispatch) =>
    services.accountsUsers.find({ query: params }).then((response) =>
      dispatch({
        type: GET_ACCOUNTS_USERS,
        payload: response,
      })
    );
}

export function getAccountUser(id) {
  return (dispatch) =>
    services.accountsUsers.get(id).then((response) =>
      dispatch({
        type: GET_ACCOUNT_USER,
        payload: response,
      })
    );
}

export function addAccountsUsers(accountUser) {
  return (dispatch) =>
    services.accountsUsers
      .create(accountUser)
      .then((response) =>
        dispatch({
          type: ADD_ACCOUNTS_USERS,
          payload: response,
        })
      )
      .then(() =>
        dispatch(getAccountsUsers({ accountId: accountUser.accountId }))
      );
}

export function updateAccountsUsers(id, accountUser) {
  return (dispatch) =>
    services.accountsUsers
      .patch(id, accountUser)
      .then((response) =>
        dispatch({
          type: UPDATE_ACCOUNTS_USERS,
          payload: response,
        })
      )
      .then(() => dispatch(getAccountsUsers()));
}

export function removeAccountsUsers(id) {
  return (dispatch) =>
    services.accountsUsers.remove(id).then((response) =>
      dispatch({
        type: REMOVE_ACCOUNTS_USERS,
        payload: response,
      })
    );
}
