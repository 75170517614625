import { services } from '../../../../api/feathers';

export const GET_RETAILERS = 'GET_RETAILERS';
export const GET_RETAILER = 'GET_RETAILER';
export const ADD_RETAILERS = 'ADD_RETAILERS';
export const UPDATE_RETAILERS = 'UPDATE_RETAILERS';
export const REMOVE_RETAILERS = 'REMOVE_RETAILERS';

export function getRetailers(params) {
  return (dispatch) =>
    services.retailers.find({ query: params }).then((response) =>
      dispatch({
        type: GET_RETAILERS,
        payload: response.data,
      })
    );
}

export function getRetailer(id) {
  return (dispatch) =>
    services.retailers.get(id).then((response) =>
      dispatch({
        type: GET_RETAILER,
        payload: response,
      })
    );
}

export function addRetailers(retailer) {
  return (dispatch) =>
    services.retailers
      .create(retailer)
      .then((response) =>
        dispatch({
          type: ADD_RETAILERS,
          payload: response,
        })
      )
      .then(() => dispatch(getRetailers()));
}

export function updateRetailers(id, retailer) {
  return (dispatch) =>
    services.retailers
      .patch(id, retailer)
      .then((response) =>
        dispatch({
          type: UPDATE_RETAILERS,
          payload: response,
        })
      )
      .then(() => dispatch(getRetailers()));
}

export function removeRetailers(id) {
  return (dispatch) =>
    services.retailers
      .remove(id)
      .then((response) =>
        dispatch({
          type: REMOVE_RETAILERS,
          payload: response,
        })
      )
      .then(() => dispatch(getRetailers()));
}
