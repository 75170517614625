import { services } from '../../../../api/feathers';

export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOM = 'GET_ROOM';
export const ADD_ROOMS = 'ADD_ROOMS';
export const UPDATE_ROOMS = 'UPDATE_ROOMS';
export const REMOVE_ROOMS = 'REMOVE_ROOMS';

export function getRooms(accountId) {
  return (dispatch) =>
    services.rooms
      .find({
        query: {
          accountId,
          includeDevices: true,
          $sort: {
            indexOrder: 1,
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_ROOMS,
          payload: response.data,
        })
      );
}

export function getRoom(id) {
  return (dispatch) =>
    services.rooms.get(id).then((response) =>
      dispatch({
        type: GET_ROOM,
        payload: response,
      })
    );
}

export function addRooms(room) {
  return (dispatch) =>
    services.rooms
      .create(room)
      .then((response) =>
        dispatch({
          type: ADD_ROOMS,
          payload: response,
        })
      )
      .then(() => dispatch(getRooms(room.accountId)));
}

export function updateRooms(id, room) {
  return (dispatch) =>
    services.rooms
      .patch(id, room)
      .then((response) =>
        dispatch({
          type: UPDATE_ROOMS,
          payload: response,
        })
      )
      .then(() => dispatch(getRooms(room.accountId)));
}
export function removeRooms(id) {
  return (dispatch) =>
    services.rooms.remove(id).then((response) =>
      dispatch({
        type: REMOVE_ROOMS,
        payload: response,
      })
    );
}
