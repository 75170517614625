import { services } from '../../../../api/feathers';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USERS = 'ADD_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';

export function getUsers(params) {
  return (dispatch) =>
    services.users.find({ query: params }).then((response) =>
      dispatch({
        type: GET_USERS,
        payload: response.data,
      })
    );
}

export function getUser(id) {
  return (dispatch) =>
    services.users.get(id).then((response) =>
      dispatch({
        type: GET_USER,
        payload: response,
      })
    );
}

export function addUsers(user) {
  return (dispatch) =>
    services.users
      .create(user)
      .then((response) =>
        dispatch({
          type: ADD_USERS,
          payload: response,
        })
      )
      .then(() => dispatch(getUsers()));
}

export function updateUsers(id, user) {
  return (dispatch) =>
    services.users
      .patch(id, user)
      .then((response) =>
        dispatch({
          type: UPDATE_USERS,
          payload: response,
        })
      )
      .then(() => dispatch(getUsers()));
}

export function removeUsers(id) {
  return (dispatch) =>
    services.users
      .remove(id)
      .then((response) =>
        dispatch({
          type: REMOVE_USERS,
          payload: response,
        })
      )
      .then(() => dispatch(getUsers()));
}
