import { services } from '../../../../api/feathers';

export const GET_RF_CONTROLS = 'GET_RF_CONTROLS';
export const GET_RF_CONTROL = 'GET_RF_CONTROL';
export const ADD_RF_CONTROLS = 'ADD_RF_CONTROLS';
export const UPDATE_RF_CONTROLS = 'UPDATE_RF_CONTROLS';
export const REMOVE_RF_CONTROLS = 'REMOVE_RF_CONTROLS';

export function getRfControls(deviceId) {
  return (dispatch) =>
    services.rfControls
      .find({
        query: {
          deviceId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_RF_CONTROLS,
          payload: response.data,
        })
      );
}

export function getRfControl(id) {
  return (dispatch) =>
    services.rfControls.get(id).then((response) =>
      dispatch({
        type: GET_RF_CONTROL,
        payload: response,
      })
    );
}

export function addRfControls(rfControl) {
  return (dispatch) =>
    services.rfControls
      .create(rfControl)
      .then((response) =>
        dispatch({
          type: ADD_RF_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getRfControls(rfControl.deviceId)));
}

export function updateRfControls(id, rfControl) {
  return (dispatch) =>
    services.rfControls
      .patch(id, rfControl)
      .then((response) =>
        dispatch({
          type: UPDATE_RF_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getRfControls(rfControl.deviceId)));
}

export function removeRfControls(id) {
  return (dispatch) =>
    services.rfControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_RF_CONTROLS,
        payload: response,
      })
    );
}
