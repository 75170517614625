import { services } from '../../../../api/feathers';

export const GET_FIRMWARE_RELEASES = 'GET_FIRMWARE_RELEASES';
export const GET_FIRMWARE_RELEASE = 'GET_FIRMWARE_RELEASE';
export const DELETE_FIRMWARE_RELEASE = 'DELETE_FIRMWARE_RELEASE';
export const ADD_FIRMWARE_RELEASES = 'ADD_FIRMWARE_RELEASES';
export const UPDATE_FIRMWARE_RELEASES = 'UPDATE_FIRMWARE_RELEASES';

export function getFirmwareReleases(params) {
  return (dispatch) =>
    services.firmwareReleases.find({ query: params }).then((response) =>
      dispatch({
        type: GET_FIRMWARE_RELEASES,
        payload: response.data,
      })
    );
}

export function getFirmwareRelease(id) {
  return (dispatch) =>
    services.firmwareReleases.get(id).then((response) =>
      dispatch({
        type: GET_FIRMWARE_RELEASE,
        payload: response,
      })
    );
}

export function addFirmwareReleases(firmwareReleases) {
  return (dispatch) =>
    services.firmwareReleases
      .create(firmwareReleases)
      .then((response) =>
        dispatch({
          type: ADD_FIRMWARE_RELEASES,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareReleases()));
}

export function updateFirmwareReleases(id, firmwareRelease) {
  return (dispatch) =>
    services.firmwareReleases
      .patch(id, firmwareRelease)
      .then((response) =>
        dispatch({
          type: UPDATE_FIRMWARE_RELEASES,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareReleases()));
}

export function removeFirmwareRelease(id) {
  return (dispatch) =>
    services.firmwareReleases
      .remove(id)
      .then((response) =>
        dispatch({
          type: DELETE_FIRMWARE_RELEASE,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareReleases()));
}
