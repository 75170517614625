import * as Actions from '../actions/rfControls.actions';

const initialState = [];

const rfControls = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_RF_CONTROLS: {
      return action.payload;
    }
    case Actions.ADD_RF_CONTROLS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_RF_CONTROLS:
    case Actions.GET_RF_CONTROL: {
      const index = state.findIndex(
        (rfControl) => rfControl.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_RF_CONTROLS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default rfControls;
