import { services } from '../../../../api/feathers';

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICE = 'GET_DEVICE';
export const ADD_DEVICES = 'ADD_DEVICES';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const REMOVE_DEVICES = 'REMOVE_DEVICES';

export function getDevices(roomId) {
  return (dispatch) =>
    services.devices
      .find({
        query: {
          roomId,
          includeControls: true,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_DEVICES,
          payload: response.data,
        })
      );
}

export function getDevice(id) {
  return (dispatch) =>
    services.devices.get(id).then((response) =>
      dispatch({
        type: GET_DEVICE,
        payload: response,
      })
    );
}

export function addDevices(device) {
  return (dispatch) =>
    services.devices
      .create(device)
      .then((response) =>
        dispatch({
          type: ADD_DEVICES,
          payload: response,
        })
      )
      .then(() => dispatch(getDevices(device.roomId)));
}

export function updateDevices(id, device) {
  return (dispatch) =>
    services.devices
      .patch(id, device)
      .then((response) =>
        dispatch({
          type: UPDATE_DEVICES,
          payload: response,
        })
      )
      .then(() => { 
        console.log('Chamou aqui', device)
        return dispatch(getDevices(device.roomId))
      });
}

export function removeDevices(id) {
  return (dispatch) =>
    services.devices.remove(id).then((response) =>
      dispatch({
        type: REMOVE_DEVICES,
        payload: response,
      })
    );
}
