import { combineReducers } from 'redux';
import accounts from '../../main/content/account/store/reducers/accounts.reducer';
import accountsUsers from '../../main/content/accountsUsers/store/reducers/accountsUsers.reducer';
// eslint-disable-next-line max-len
import usersAccountPermissions from '../../main/content/accountsUsers/permissions/store/reducers/usersAccountPermissions.reducer';
import rooms from '../../main/content/rooms/store/reducers/rooms.reducer';
import devices from '../../main/content/devices/store/reducers/devices.reducer';
import deviceLogs from '../../main/content/devices/store/reducers/deviceLogs.reducer';
import scenarios from '../../main/content/scenarios/store/reducers/scenarios.reducer';
import scenariosSchedules from '../../main/content/scenariosSchedules/store/reducers/scenariosSchedules.reducer';
import rfControls from '../../main/content/rfControls/store/reducers/rfControls.reducer';
import irControls from '../../main/content/irControls/store/reducers/irControls.reducer';
import switchControls from '../../main/content/switchControls/store/reducers/switchControls.reducer';
import irModels from '../../main/content/irModels/store/reducers/irModels.reducer';
import commands from '../../main/content/commands/store/reducers/commands.reducer';
import shortcuts from '../../main/content/shortcuts/store/reducers/shortcuts.reducer';
import channelImages from '../../main/content/channelImages/store/reducers/channelImages.reducer';
import users from '../../main/content/users/store/reducers/users.reducer';
import roles from '../../main/content/users/store/reducers/roles.reducer';
import retailers from '../../main/content/retailers/store/reducers/retailers.reducer';
import centralLogins from '../../main/content/account/store/reducers/centralLogins.reducer';
import roomsDeviceLogs from '../../main/content/account/store/reducers/accountLogs.reducer';
import lockControls from '../../main/content/lockControls/store/reducers/lockControls.reducer';
// eslint-disable-next-line max-len
import firmwareReleases from '../../main/content/firmwareReleases/store/reducers/firmwareReleases.reducers';
import firmwareVersions from '../../main/content/firmwareVersions/store/reducers/firmwareVersions.reducer';
import firmwareUpdates from '../../main/content/firmwareUpdates/store/reducers/firmwareUpdates.reducer';
import smartplugControls from '../../main/content/smartplugControls/store/reducers/smartplugControls.reducer';
import deviceRegistrations from '../../main/content/deviceRegistrations/store/reducers/deviceRegistrations.reducer';
import deviceTypes from '../../main/content/deviceTypes/store/reducers/deviceTypes.reducer';
import centralStatusChecks from '../../main/content/account/store/reducers/centralStatusChecks.reducer';
import usageLogs from '../../main/content/account/store/reducers/usageLogs.reducer';
import liveUsageLogsEnabled from '../../main/content/account/store/reducers/liveUsageLogsEnabled.reducer';
import quickPanel from '../../main/quickPanel/store/reducers';
import auth from '../../auth/store/reducers';
import fuse from './fuse';
import ledControls from '../../main/content/ledControls/store/reducers/ledControls.reducer';

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    fuse,
    quickPanel,
    accounts,
    accountsUsers,
    rooms,
    devices,
    deviceLogs,
    scenarios,
    rfControls,
    irControls,
    switchControls,
    irModels,
    commands,
    shortcuts,
    scenariosSchedules,
    usersAccountPermissions,
    channelImages,
    users,
    roles,
    retailers,
    centralLogins,
    roomsDeviceLogs,
    lockControls,
    firmwareReleases,
    firmwareVersions,
    firmwareUpdates,
    smartplugControls,
    deviceRegistrations,
    deviceTypes,
    centralStatusChecks,
    usageLogs,
    liveUsageLogsEnabled,
    ledControls,
    ...asyncReducers,
  });

export default createReducer;
