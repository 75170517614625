import * as Actions from '../actions/usersAccountPermissions.actions';

const initialState = [];

const usersAccountPermissions = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_USERS_ACCOUNTS_PERMISSIONS:
      return action.payload;

    case Actions.ADD_USERS_ACCOUNTS_PERMISSIONS:
      return [...state, action.payload];

    case Actions.UPDATE_USERS_ACCOUNTS_PERMISSIONS:
    case Actions.GET_USER_ACCOUNT_PERMISSION: {
      const index = state.findIndex(
        (userAccountPermission) =>
          userAccountPermission.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_USERS_ACCOUNTS_PERMISSIONS: {
      const removeState = state.filter(
        (userAccountPermission) =>
          userAccountPermission.id !== action.payload.id
      );
      return [...removeState];
    }

    default:
      return state;
  }
};

export default usersAccountPermissions;
