
import * as Actions from '../actions/scenariosSchedules.actions';

const initialState = [];

const scenariosSchedules = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_SCENARIOS_SCHEDULES: {
      return action.payload;
    }
    case Actions.ADD_SCENARIOS_SCHEDULES: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_SCENARIOS_SCHEDULES:
    case Actions.GET_SCENARIO_SCHEDULES: {
      const index = state.findIndex(
        (scenariosSchedule) => scenariosSchedule.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_SCENARIOS_SCHEDULES: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default scenariosSchedules;
