import { services } from '../../../../api/feathers';

export const GET_USAGE_LOGS = 'GET_USAGE_LOGS';
export const UPDATE_USAGE_LOGS = 'UPDATE_USAGE_LOGS';
export const LIVE_USAGE_LOGS_ENABLED = 'LIVE_USAGE_LOGS_ENABLED';

export function getUsageLogs(query) {
  return (dispatch) =>
    services.usageLogs.find(query).then((response) => {
      dispatch({
        type: GET_USAGE_LOGS,
        payload: response.data,
      });
    });
}

export function liveUsageLogs(query) {
  return (dispatch) =>
    services.usageLogs.find(query).then((response) => {
      dispatch({
        type: UPDATE_USAGE_LOGS,
        payload: response.data,
      });
    });
}
