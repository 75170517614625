import { services } from '../../../../api/feathers';

export const GET_CHANNEL_IMAGES = 'GET_CHANNEL_IMAGES';
export const GET_CHANNEL_IMAGE = 'GET_CHANNEL_IMAGE';
export const ADD_CHANNEL_IMAGES = 'ADD_CHANNEL_IMAGES';
export const UPDATE_CHANNEL_IMAGES = 'UPDATE_CHANNEL_IMAGES';

export function getChannelImages(params) {
  return (dispatch) =>
    services.channelImages.find({ query: params }).then((response) =>
      dispatch({
        type: GET_CHANNEL_IMAGES,
        payload: response.data,
      })
    );
}

export function getChannelImage(id) {
  return (dispatch) =>
    services.channelImages.get(id).then((response) =>
      dispatch({
        type: GET_CHANNEL_IMAGE,
        payload: response,
      })
    );
}

export function addChannelImages(channelImage) {
  return (dispatch) =>
    services.channelImages
      .create(channelImage)
      .then((response) =>
        dispatch({
          type: ADD_CHANNEL_IMAGES,
          payload: response,
        })
      )
      .then(() => dispatch(getChannelImages()));
}

export function updateChannelImages(id, channelImage) {
  return (dispatch) =>
    services.channelImages
      .patch(id, channelImage)
      .then((response) =>
        dispatch({
          type: UPDATE_CHANNEL_IMAGES,
          payload: response,
        })
      )
      .then(() => dispatch(getChannelImages()));
}
