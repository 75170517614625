/* eslint-disable default-param-last */
import * as Actions from '../actions/devices.actions';

const initialState = [];

const devices = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DEVICES:
      return action.payload;

    case Actions.ADD_DEVICES:
      return [...state, action.payload];

    case Actions.UPDATE_DEVICES:
    case Actions.GET_DEVICE: {
      const index = state.findIndex(
        (device) => device.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_DEVICES: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default devices;
