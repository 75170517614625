import { services } from '../../../../api/feathers';

export const GET_FIRMWARE_VERSIONS = 'GET_FIRMWARE_VERSIONS';
export const GET_FIRMWARE_VERSION = 'GET_FIRMWARE_VERSION';
export const ADD_FIRMWARE_VERSIONS = 'ADD_FIRMWARE_VERSIONS';
export const UPDATE_FIRMWARE_VERSIONS = 'UPDATE_FIRMWARE_VERSIONS';
export const REMOVE_FIRMWARE_VERSIONS = 'REMOVE_FIRMWARE_VERSIONS';

export function getFirmwareVersions(query = {}) {
  return (dispatch) =>
    services.firmwareVersions.find(query).then((response) =>
      dispatch({
        type: GET_FIRMWARE_VERSIONS,
        payload: response.data,
      })
    );
}

export function getFirmwareVersion(id) {
  return (dispatch) =>
    services.firmwareVersions.get(id).then((response) =>
      dispatch({
        type: GET_FIRMWARE_VERSION,
        payload: response,
      })
    );
}

export function addFirmwareVersions(firmwareVersion) {
  return (dispatch) =>
    services.firmwareVersions
      .create(firmwareVersion)
      .then((response) =>
        dispatch({
          type: ADD_FIRMWARE_VERSIONS,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareVersions()));
}

export function updateFirmwareVersions(id, firmwareVersion) {
  return (dispatch) =>
    services.firmwareVersions
      .patch(id, firmwareVersion)
      .then((response) =>
        dispatch({
          type: UPDATE_FIRMWARE_VERSIONS,
          payload: response,
        })
      )
      .then(() => dispatch(getFirmwareVersions()));
}

export function removeFirmwareVersions(id) {
  return (dispatch) =>
    services.firmwareVersions.remove(id).then((response) =>
      dispatch({
        type: REMOVE_FIRMWARE_VERSIONS,
        payload: response,
      })
    );
}
