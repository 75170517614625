import { services } from '../../../../api/feathers';

export const GET_LOCK_CONTROLS = 'GET_LOCK_CONTROLS';
export const GET_LOCK_CONTROL = 'GET_LOCK_CONTROL';
export const ADD_LOCK_CONTROLS = 'ADD_LOCK_CONTROLS';
export const UPDATE_LOCK_CONTROLS = 'UPDATE_LOCK_CONTROLS';
export const REMOVE_LOCK_CONTROLS = 'REMOVE_LOCK_CONTROLS';

export function getLockControls(deviceId) {
  return (dispatch) =>
    services.lockControls
      .find({
        query: {
          deviceId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_LOCK_CONTROLS,
          payload: response.data,
        })
      );
}

export function getLockControl(id) {
  return (dispatch) =>
    services.lockControls.get(id).then((response) =>
      dispatch({
        type: GET_LOCK_CONTROL,
        payload: response,
      })
    );
}

export function addLockControls(lockControl) {
  return (dispatch) =>
    services.lockControls
      .create(lockControl)
      .then((response) =>
        dispatch({
          type: ADD_LOCK_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getLockControls(lockControl.deviceId)));
}

export function updateLockControls(id, lockControl) {
  return (dispatch) =>
    services.lockControls
      .patch(id, lockControl)
      .then((response) =>
        dispatch({
          type: UPDATE_LOCK_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getLockControls(lockControl.deviceId)));
}

export function removeLockControls(id) {
  return (dispatch) =>
    services.lockControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_LOCK_CONTROLS,
        payload: response,
      })
    );
}
