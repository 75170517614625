/* eslint-disable default-param-last */
import * as Actions from '../actions/centralLogins.actions';

const initialState = [];

const centralLogins = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CENTRAL_LOGINS:
      return action.payload;

    default:
      return state;
  }
};

export default centralLogins;
