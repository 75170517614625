/* eslint-disable default-param-last */
import * as Actions from '../actions/deviceTypes.actions';

const initialState = [];

const deviceTypes = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DEVICE_TYPES:
      return action.payload;

    case Actions.ADD_DEVICE_TYPES:
      return [...state, action.payload];

    case Actions.UPDATE_DEVICES_TYPES:
    case Actions.GET_DEVICE_TYPE: {
      const index = state.findIndex(
        (deviceType) => deviceType.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_DEVICES_TYPES: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default deviceTypes;
