/* eslint-disable default-param-last */
import * as Action from '../actions/usageLogs.actions';

const initialState = false;

const liveUsageLogsEnabled = (state = initialState, action) => {
  switch (action.type) {
    case Action.LIVE_USAGE_LOGS_ENABLED:
      return action.payload;

    default:
      return state;
  }
};

export default liveUsageLogsEnabled;
