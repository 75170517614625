import { services } from '../../../../api/feathers';

export const GET_SMARTPLUG_CONTROLS = 'GET_SMARTPLUG_CONTROLS';
export const GET_SMARTPLUG_CONTROL = 'GET_SMARTPLUG_CONTROL';
export const ADD_SMARTPLUG_CONTROLS = 'ADD_SMARTPLUG_CONTROLS';
export const UPDATE_SMARTPLUG_CONTROLS = 'UPDATE_SMARTPLUG_CONTROLS';
export const REMOVE_SMARTPLUG_CONTROLS = 'REMOVE_SMARTPLUG_CONTROLS';

export function getSmartplugControls(deviceId) {
  return (dispatch) =>
    services.smartplugControls
      .find({
        query: {
          deviceId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_SMARTPLUG_CONTROLS,
          payload: response.data,
        })
      );
}

export function getSmartplugControl(id, query = {}) {
  return (dispatch) =>
    services.smartplugControls.get(id, query).then((response) =>
      dispatch({
        type: GET_SMARTPLUG_CONTROL,
        payload: response,
      })
    );
}

export function addSmartplugControls(smartplugControl) {
  return (dispatch) =>
    services.smartplugControls
      .create(smartplugControl)
      .then((response) =>
        dispatch({
          type: ADD_SMARTPLUG_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getSmartplugControls(smartplugControl.deviceId)));
}

export function updateSmartplugControls(id, smartplugControl) {
  return (dispatch) =>
    services.smartplugControls.patch(id, smartplugControl).then((response) =>
      dispatch({
        type: UPDATE_SMARTPLUG_CONTROLS,
        payload: response,
      })
    );
}

export function removeSmartplugControls(id) {
  return (dispatch) =>
    services.smartplugControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_SMARTPLUG_CONTROLS,
        payload: response,
      })
    );
}
