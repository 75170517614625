import * as Actions from '../actions/roles.actions';

const initialState = [];

const roles = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_ROLES: {
      return action.payload;
    }
    case Actions.ADD_ROLES: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_ROLES:
    case Actions.GET_ROLE: {
      const newState = state;
      const index = state.findIndex((role) => role.id === action.payload.id);
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_ROLES: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default roles;
