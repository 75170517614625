import { services } from '../../../../api/feathers';

export const GET_ROOMS_DEVICES_LOGS = 'GET_ROOMS_DEVICES_LOGS';
export const GET_INFLUX_DEVICES_LOG = 'GET_INFLUX_DEVICES_LOG';
export const GET_ELASTIC_DEVICES_LOG = 'GET_ELASTIC_DEVICES_LOG';

export function getInfluxevicesLog(macs) {
  return (dispatch) =>
    services.influxDeviceLogs
      .find({
        query: {
          macs,
          limit: 1,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_INFLUX_DEVICES_LOG,
          payload: response,
        })
      )
      .catch((error) => console.log(error));
}

export function getElasticSevicesLog(accountId) {
  return (dispatch) =>
    services.elasticServices
      .find({
        query: {
          accountId,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_ELASTIC_DEVICES_LOG,
          payload: response.hits.hits,
        })
      )
      .catch((error) => console.log(error));
}

export function getRoomDevicesLogs(accountId) {
  return (dispatch) =>
    services.rooms
      .find({
        query: {
          accountId,
          includeDevices: true,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) => {
        dispatch(getElasticSevicesLog(accountId));
        return dispatch({
          type: GET_ROOMS_DEVICES_LOGS,
          payload: response.data,
        });
      });
}
