/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import {
  withStyles,
  AppBar,
  Typography,
  Hidden,
  Icon,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logoutUser, tokenValidate } from '../auth/store/actions';
import { FuseNavigation, FuseLayouts } from '../@fuse';
import packageJson from '../../package.json';

const styles = (theme) => ({
  root: {
    '& .user': {
      '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  avatar: {
    width: 72,
    height: 72,
    position: 'absolute',
    top: 92,
    padding: 8,
    background: theme.palette.background.default,
    boxSizing: 'content-box',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > img': {
      borderRadius: '50%',
    },
  },
});

function UserHeader(props) {
  const { user } = props;
  const { version } = packageJson;
  const handleLogout = () => {
    props.logoutUser();
  };

  return (
    <AppBar
      position="static"
      color="primary"
      elevation={0}
      className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0"
    >
      <Typography
        className="username text-16 whitespace-no-wrap"
        color="inherit"
      >
        {user.name}
      </Typography>
      <Typography
        className="email text-13 mt-8 opacity-50 whitespace-no-wrap"
        color="inherit"
      >
        {user.email}
      </Typography>
      <button
        className="mt-4"
        type="button"
        style={{
          color: '#fff',
          backgroundColor: '#039be5',
          display: 'inline-flex',
          alignItems: 'center',
          border: 0,
          borderRadius: '50px',
          padding: '5px 15px',
          cursor: 'pointer',
        }}
        onClick={handleLogout}
      >
        Sair{' '}
        <Icon style={{ marginLeft: 8, fontSize: 16 }} color="action">
          exit_to_app
        </Icon>
      </button>
      <span style={{ marginTop: 30 }}>Versão: {version}</span>
    </AppBar>
  );
}

function MainNavbar(props) {
  const { classes, navigation, layoutStyle, user } = props;

  useEffect(() => {
    if (user && !user.email) {
      tokenValidate();
    }
  }, [user, tokenValidate]);

  const navigationLayout = FuseLayouts[layoutStyle].type;
  return (
    <div className={classes.root}>
      {navigationLayout === 'vertical' ? (
        <>
          <UserHeader user={user} logoutUser={props.logoutUser} />
          <FuseNavigation navigation={navigation} layout={navigationLayout} />
        </>
      ) : (
        <>
          <Hidden lgUp>
            <UserHeader user={user} logoutUser={props.logoutUser} />
          </Hidden>
          <FuseNavigation navigation={navigation} layout={navigationLayout} />
        </>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutUser,
      tokenValidate,
    },
    dispatch
  );
}

function mapStateToProps({ fuse, auth }) {
  return {
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar))
);
