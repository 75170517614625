/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '../@fuse/index';
import { RoutesConfig } from '../main/RoutesConfig';
import { LoginConfig } from '../main/content/login/LoginConfig';
import { RecoverPasswordConfig } from '../main/content/recoverPassword/RecoverPasswordConfig';

const routeConfigs = [RoutesConfig, LoginConfig, RecoverPasswordConfig];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    component: () => <Redirect to="/account/list" />,
  },
];
