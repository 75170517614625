import { services } from '../../../../api/feathers';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLE = 'GET_ROLE';
export const ADD_ROLES = 'ADD_ROLES';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const REMOVE_ROLES = 'REMOVE_ROLES';

export function getRoles(params) {
  return (dispatch) =>
    services.roles.find({ query: params }).then((response) =>
      dispatch({
        type: GET_ROLES,
        payload: response.data,
      })
    );
}

export function getRole(id) {
  return (dispatch) =>
    services.roles.get(id).then((response) =>
      dispatch({
        type: GET_ROLE,
        payload: response,
      })
    );
}

export function addRoles(role) {
  return (dispatch) =>
    services.roles
      .create(role)
      .then((response) =>
        dispatch({
          type: ADD_ROLES,
          payload: response,
        })
      )
      .then(() => dispatch(getRoles()));
}

export function updateRoles(id, role) {
  return (dispatch) =>
    services.roles
      .patch(id, role)
      .then((response) =>
        dispatch({
          type: UPDATE_ROLES,
          payload: response,
        })
      )
      .then(() => dispatch(getRoles()));
}

export function removeRoles(id) {
  return (dispatch) =>
    services.roles
      .remove(id)
      .then((response) =>
        dispatch({
          type: REMOVE_ROLES,
          payload: response,
        })
      )
      .then(() => dispatch(getRoles()));
}
