import { services } from '../../../../api/feathers';

export const GET_SCENARIOS = 'GET_SCENARIOS';
export const GET_SCENARIO = 'GET_SCENARIO';
export const ADD_SCENARIOS = 'ADD_SCENARIOS';
export const ADD_SCENARIOS_AUTO = 'ADD_SCENARIOS_AUTO';
export const UPDATE_SCENARIOS = 'UPDATE_SCENARIOS';
export const REMOVE_SCENARIOS = 'REMOVE_SCENARIOS';

export function getScenarios(accountId) {
  return (dispatch) =>
    services.scenarios
      .find({
        query: {
          accountId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_SCENARIOS,
          payload: response,
        })
      );
}

export function getScenario(id) {
  return (dispatch) =>
    services.scenarios.get(id).then((response) =>
      dispatch({
        type: GET_SCENARIO,
        payload: response,
      })
    );
}

export function addScenarios(account) {
  return (dispatch) =>
    services.scenarios.create(account).then((response) =>
      dispatch({
        type: ADD_SCENARIOS,
        payload: response,
      })
    );
}
export function addScenariosAuto(accountId) {
  return (dispatch) =>
    services.scenarios.create(accountId).then((response) =>
      dispatch({
        type: ADD_SCENARIOS_AUTO,
        payload: response,
      })
    );
}

export function updateScenarios(id, account) {
  return (dispatch) =>
    services.scenarios
      .patch(id, account)
      .then((response) =>
        dispatch({
          type: UPDATE_SCENARIOS,
          payload: response,
        })
      )
      .then(() => dispatch(getScenarios()));
}
export function removeScenarios(id) {
  return (dispatch) =>
    services.scenarios.remove(id).then((response) =>
      dispatch({
        type: REMOVE_SCENARIOS,
        payload: response,
      })
    );
}
