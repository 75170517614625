
import * as Actions from '../actions/irModels.actions';

const initialState = [];

const irModels = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_IR_MODELS: {
      return action.payload;
    }
    case Actions.ADD_IR_MODELS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_IR_MODELS:
    case Actions.GET_IR_MODEL: {
      const index = state.findIndex(
        (irModel) => irModel.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    default:
      return state;
  }
};

export default irModels;
