import * as Actions from '../actions/centralStatusChecks.actions';

const initialState = [];

const centralStatusChecks = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CENTRAL_STATUS_CHECKS:
      return action.payload;

    case Actions.ADD_CENTRAL_STATUS_CHECKS:
      return [action.payload];

    case Actions.UPDATE_CENTRAL_STATUS_CHECKS:
    case Actions.GET_CENTRAL_STATUS_CHECK: {
      const index = state.findIndex(
        (centralStatusCheck) => centralStatusCheck.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_CENTRAL_STATUS_CHECKS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }

    default:
      return state;
  }
};

export default centralStatusChecks;
