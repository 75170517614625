import { services } from '../../../../../api/feathers';

export const GET_USERS_ACCOUNTS_PERMISSIONS = 'GET_USERS_ACCOUNTS_PERMISSIONS';
export const GET_USER_ACCOUNT_PERMISSION = 'GET_USER_ACCOUNT_PERMISSION';
export const ADD_USERS_ACCOUNTS_PERMISSIONS = 'ADD_USERS_ACCOUNTS_PERMISSIONS';
export const UPDATE_USERS_ACCOUNTS_PERMISSIONS =
  'UPDATE_USERS_ACCOUNTS_PERMISSIONS';
export const REMOVE_USERS_ACCOUNTS_PERMISSIONS =
  'REMOVE_USERS_ACCOUNTS_PERMISSIONS';

export function getUsersAccountPermissions(params = {}) {
  return (dispatch) =>
    services.usersAccountPermissions
      .find({ query: { ...params } })
      .then((response) =>
        dispatch({
          type: GET_USERS_ACCOUNTS_PERMISSIONS,
          payload: response.data,
        })
      );
}

export function getUserAccountPermission(id) {
  return (dispatch) =>
    services.usersAccountPermissions.get(id).then((response) =>
      dispatch({
        type: GET_USER_ACCOUNT_PERMISSION,
        payload: response,
      })
    );
}

export function addUsersAccountPermissions(userAccountPermission) {
  return (dispatch) =>
    services.usersAccountPermissions
      .create(userAccountPermission)
      .then((response) =>
        dispatch({
          type: ADD_USERS_ACCOUNTS_PERMISSIONS,
          payload: response,
        })
      );
}

export function updateUsersAccountPermissions(id, userAccountPermission) {
  return (dispatch) =>
    services.usersAccountPermissions
      .patch(id, userAccountPermission)
      .then((response) =>
        dispatch({
          type: UPDATE_USERS_ACCOUNTS_PERMISSIONS,
          payload: response,
        })
      );
}

export function removeUsersAccountPermissions(id) {
  return (dispatch) =>
    services.usersAccountPermissions.remove(id).then((response) =>
      dispatch({
        type: REMOVE_USERS_ACCOUNTS_PERMISSIONS,
        payload: response,
      })
    );
}
