import * as Actions from '../actions/firmwareUpdates.actions';

const initialState = [];

const firmwareUpdates = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_FIRMWARE_UPDATES: {
      return action.payload;
    }
    case Actions.ADD_FIRMWARE_UPDATES: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_FIRMWARE_UPDATES:
    case Actions.GET_FIRMWARE_UPDATE:{
      const index = state.findIndex(
        (firmwareUpdate) => firmwareUpdate.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    default:
      return state;
  }
};

export default firmwareUpdates;
