/* eslint-disable no-underscore-dangle */
import * as Action from '../actions/accountLogs.actions';

const initialState = [];

const roomsDeviceLogs = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_ROOMS_DEVICES_LOGS:
      return action.payload;
    case Action.GET_INFLUX_DEVICES_LOG: {
      const newState = state.map((room) => {
        room.devices.map((dv) => {
          const newLog = action.payload.find(
            (log) =>
              log.topic === `LOG/${dv.mac}` ||
              log.topic === `NEWDEVICE/${dv.mac}`
          );
          if (newLog) {
            const data = JSON.parse(newLog._value);
            let newDeviceLog = dv.deviceLog;
            newDeviceLog = { ...newLog, ...data };
            return newDeviceLog;
          }
          return dv;
        });
        return room;
      });
      return [...newState];
    }

    case Action.GET_ELASTIC_DEVICES_LOG: {
      const newState = state.map((room) => {
        room.devices.map((dv) => {
          const newLog = action.payload.find(
            (log) => log && log._source && log._source.deviceId === dv.id
          );
          if (newLog) {
            const newDeviceLogSource = dv;
            newDeviceLogSource.deviceLog = { ...newLog._source };
            return newDeviceLogSource;
          }
          return dv;
        });
        return room;
      });
      const logCentral = action.payload.find(
        (log) => log._source.deviceType === 'CENTRAL'
      );
      if (logCentral) {
        return [
          {
            id: 'central',
            name: 'Central',
            devices: [
              {
                name: 'CENTRAL',
                id: 'device_central',
                mac: logCentral._source.macAddress,
                deviceLog: {
                  ...logCentral._source,
                },
              },
            ],
          },
          ...newState,
        ];
      }
      return newState;
    }

    default:
      return state;
  }
};

export default roomsDeviceLogs;
