import { services } from '../../../../api/feathers';

export const GET_CENTRAL_STATUS_CHECKS = 'GET_CENTRAL_STATUS_CHECKS';
export const GET_CENTRAL_STATUS_CHECK = 'GET_CENTRAL_STATUS_CHECK';
export const ADD_CENTRAL_STATUS_CHECKS = 'ADD_CENTRAL_STATUS_CHECKS';
export const UPDATE_CENTRAL_STATUS_CHECKS = 'UPDATE_CENTRAL_STATUS_CHECKS';
export const REMOVE_CENTRAL_STATUS_CHECKS = 'REMOVE_CENTRAL_STATUS_CHECKS';
export const REMOTE_CENTRAL_STATUS_CHECK = 'REMOTE_CENTRAL_STATUS_CHECK';

export function getCentralStatusChecks(query = {}) {
  return (dispatch) =>
    services.centralStatusChecks.find(query).then((response) =>
      dispatch({
        type: GET_CENTRAL_STATUS_CHECKS,
        payload: response.data,
      })
    );
}

export function getCentralStatusCheck(id) {
  return (dispatch) =>
    services.centralStatusChecks.get(id).then((response) =>
      dispatch({
        type: GET_CENTRAL_STATUS_CHECK,
        payload: response,
      })
    );
}

export function addCentralStatusChecks(centralStatusCheck) {
  return (dispatch) =>
    services.centralStatusChecks.create(centralStatusCheck).then((response) =>
      dispatch({
        type: ADD_CENTRAL_STATUS_CHECKS,
        payload: response,
      })
    );
}

export function updateCentralStatusChecks(id, centralStatusCheck) {
  return (dispatch) =>
    services.centralStatusChecks
      .patch(id, centralStatusCheck)
      .then((response) =>
        dispatch({
          type: UPDATE_CENTRAL_STATUS_CHECKS,
          payload: response,
        })
      );
}

export function removeCentralStatusChecks(id) {
  return (dispatch) =>
    services.centralStatusChecks.remove(id).then((response) =>
      dispatch({
        type: REMOVE_CENTRAL_STATUS_CHECKS,
        payload: response,
      })
    );
}
export function addRemoteAccess(deviceId) {
  return (dispatch) =>
    services.remoteAccess.create(deviceId).then((response) =>
      dispatch({
        type: REMOTE_CENTRAL_STATUS_CHECK,
        payload: response,
      })
    );
}
