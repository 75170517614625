import * as Actions from '../actions/retailers.actions';

const initialState = [];

const retailers = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_RETAILERS: {
      return action.payload;
    }
    case Actions.ADD_RETAILERS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_RETAILERS:
    case Actions.GET_RETAILER: {
      const index = state.findIndex(
        (retailer) => retailer.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    case Actions.REMOVE_RETAILERS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }

    default:
      return state;
  }
};

export default retailers;
