import { services } from '../../../../api/feathers';

export const GET_DEVICE_TYPES = 'GET_DEVICE_TYPES';
export const GET_DEVICE_TYPE = 'GET_DEVICE_TYPE';
export const ADD_DEVICE_TYPES = 'ADD_DEVICE_TYPES';
export const UPDATE_DEVICES_TYPES = 'UPDATE_DEVICES_TYPES';
export const REMOVE_DEVICES_TYPES = 'REMOVE_DEVICES_TYPES';

export function getDeviceTypes(params) {
  return (dispatch) =>
    services.deviceTypes.find({ query: params }).then((response) =>
      dispatch({
        type: GET_DEVICE_TYPES,
        payload: response.data,
      })
    );
}

export function getDeviceType(id) {
  return (dispatch) =>
    services.deviceTypes.get(id).then((response) =>
      dispatch({
        type: GET_DEVICE_TYPE,
        payload: response,
      })
    );
}

export function addDeviceTypes(deviceType) {
  return (dispatch) =>
    services.deviceTypes
      .create(deviceType)
      .then((response) =>
        dispatch({
          type: ADD_DEVICE_TYPES,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceTypes()));
}

export function updateDeviceTypes(id, deviceType) {
  return (dispatch) =>
    services.deviceTypes
      .patch(id, deviceType)
      .then((response) =>
        dispatch({
          type: UPDATE_DEVICES_TYPES,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceTypes()));
}

export function removeDeviceTypes(id) {
  return (dispatch) =>
    services.deviceTypes
      .remove(id)
      .then((response) =>
        dispatch({
          type: REMOVE_DEVICES_TYPES,
          payload: response,
        })
      )
      .then(() => dispatch(getDeviceTypes()));
}
