/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import { withStyles, Button, TextField, FormControl } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as FuseActions from '../../../store/actions';
import * as Actions from '../users/store/actions/users.actions';
import { services } from '../../api/feathers';

const styles = (theme) => ({
  root: {
    width: '100%'
  }
});

class JWTLoginTab extends Component {
  
  constructor(props) {
    super();
    this.state = {
      password: '',
      confirmPassword: ''
    };
  }

  handleChange = (e) => {
    const change = {
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    };
    this.setState({ ...change });
    console.log('STATE: ', this.state);
  };

  handleMessage = (message, variant) => {
    const { showMessage } = this.props;
    showMessage({
      message, // text or html
      autoHideDuration: 6000, // ms
      anchorOrigin: {
        vertical: 'top', // top bottom
        horizontal: 'center' // left center right
      },
      variant // success error info warning null
    });

  }

  onSubmit = (ev) => {
    ev.preventDefault();
    const { match } = this.props;
    const { userId, code } = match.params
    const {
      confirmPassword, 
      password,
    } = this.state;

    if (!password) {
      this.handleMessage('Informe a senha do usuário.', 'error');
      return false;
    }
    
    if (password.length < 8) {
      this.handleMessage('A senha do usuário deve possuir no minimo 8 caracteres.', 'error');
      return false;
    }
    
    if (password !== confirmPassword) {
      this.handleMessage('A senha e a confirmação de senha devem ser iguais.', 'error');
      return false;
    }
    
    services.resetPassword.patch(userId, {
      password,
      code,
    }).then(() => {
      this.handleMessage('Senha salva com sucesso.', 'success');
    }).catch((error) => {
      if(error.message) {
        this.handleMessage(
          error.message === 'Código inválido.'
            ? 'Código de verificação inválido. Favor entrar em contato com o suporte técnico.' 
            : error.message, 'error');
      } else {
        this.handleMessage('Erro ao salvar senha.', 'error');
      }
      
    });
    // this.disableButton();
    return true;
  };

  render()
  {
    const {classes} = this.props;
    const { password, confirmPassword } = this.state;
    return (
      <div className={classes.root}>
        <form 
          onSubmit={this.onSubmit} 
          className="flex flex-col justify-center w-full"
        >
          <FormControl style={{ marginTop: 15 }} className="flex flex-col justify-center w-full">
            <TextField
              className="mb-16"
              onChange={this.handleChange}
              label="Nova Senha"
              autoFocus
              name="password"
              variant="outlined"
              type='password'
              value={password}
            />
          </FormControl>

          <FormControl style={{ marginTop: 15 }} className="flex flex-col justify-center w-full">
            <TextField
              className="mb-16"
              onChange={this.handleChange}
              label="Confirme sua senha"
              autoFocus
              name="confirmPassword"
              variant="outlined"
              type='password'
              value={confirmPassword}
            />
          </FormControl>
          <Button
            style={{ marginTop: 15 }} 
            type="submit"
            variant="contained"
            color="primary"
            className="w-full mx-auto mt-16 normal-case"
            aria-label="LOG IN"
            value="legacy"
          >
            Salvar Nova Senha
          </Button>

        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch)
{
  return bindActionCreators({
    updateUsers: Actions.updateUsers,
    showMessage: FuseActions.showMessage
  }, dispatch);
}


export default withStyles(styles, {withTheme: true})(withRouter(connect(null, mapDispatchToProps)(JWTLoginTab)));
