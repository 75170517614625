import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import superagent from 'superagent';
import history from '../../history';
import SERVER_URL from './serverUrl';

const app = feathers();

app
  .configure(rest(SERVER_URL).superagent(superagent))
  .configure(auth({ storage: window.localStorage }));

const services = {
  users: app.service('users'),
  retailers: app.service('retailers'),
  roles: app.service('roles'),
  accounts: app.service('accounts'),
  accountsUsers: app.service('users-account'),
  usersAccountPermissions: app.service('users-account-permissions'),
  rooms: app.service('rooms'),
  devices: app.service('devices'),
  scenarios: app.service('scenarios'),
  scenariosSchedules: app.service('scenarios-schedules'),
  rfControls: app.service('rf-controls'),
  switchControls: app.service('switch-controls'),
  irModels: app.service('ir-models'),
  irControls: app.service('ir-controls'),
  commands: app.service('commands'),
  shortcuts: app.service('shortcuts'),
  channelImages: app.service('channel-images'),
  centralLogins: app.service('central-logins'),
  deviceLogs: app.service('device-logs'),
  remoteAccess: app.service('remote-access'),
  importAccounts: app.service('import-accounts'),
  influxDeviceLogs: app.service('influx-device-logs'),
  lockControls: app.service('lock-controls'),
  firmwareReleases: app.service('firmware-releases'),
  firmwareVersions: app.service('firmware-versions'),
  firmwareUpdates: app.service('firmware-updates'),
  smartplugControls: app.service('smartplug-controls'),
  deviceRegistrations: app.service('device-registrations'),
  deviceTypes: app.service('device-types'),
  importFirmwareVersions: app.service('import-firmwares'),
  elasticServices: app.service('elastic-services'),
  mqttCommands: app.service('mqtt-commands'),
  centralStatusChecks: app.service('central-status-checks'),
  resetPassword: app.service('reset-password'),
  actionUserLogs: app.service('action-user-logs'),
  ledControls: app.service('led-controls'),
  usageLogs: app.service('usage-logs'),
};

app.hooks({
  error: {
    all(context) {
      if (
        context.path !== 'login' &&
        context.error &&
        context.error.name === 'NotAuthenticated'
      ) {
        history.push({
          pathname: `/login`,
        });
      }
      return context;
    },
  },
});

export { app, services };
