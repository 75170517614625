function getServerUrl(locationOrigin) {
  if (locationOrigin === 'https://dashboard.smarteklife.com') {
    return 'https://api.smarteklife.com';
  }

  if (locationOrigin === 'https://dashteste.smarteklife.com') {
    return 'https://apiteste.smarteklife.com';
  }
  return 'http://localhost:3030';
}

const SERVER_URL = getServerUrl(window.location.origin);

export default SERVER_URL;
