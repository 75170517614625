import React from 'react';
import { Typography, LinearProgress, Icon, Button } from '@material-ui/core';

function Loading(props) {
  if (props.error) {
    console.log(props.error);
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Icon className="text-40 mb-16" color="action">
          error_outline
        </Icon>
        <Typography className="text-20 mb-16" color="textSecondary">
          Algo deu errado
        </Typography>
        <Button onClick={props.retry} variant="contained" color="secondary">
          Tentar Novamente
        </Button>
      </div>
    );
  }
  if (props.timedOut) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Icon className="text-40 mb-16" color="action">
          sentiment_dissatisfied
        </Icon>
        <Typography className="text-20 mb-16" color="textSecondary">
          Está levando muito tempo...
        </Typography>
        <Button onClick={props.retry} variant="contained" color="secondary">
          Tentar Novamente
        </Button>
      </div>
    );
  }
  if (props.pastDelay) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Typography className="text-20 mb-16" color="textSecondary">
          Carregando...
        </Typography>
        <LinearProgress className="w-xs" color="secondary" />
      </div>
    );
  }
  return null;
}

export default Loading;
