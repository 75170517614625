import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Card, CardContent, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { FuseAnimate } from '../../../@fuse';
import JWTLoginTab from './tabs/JWTLoginTab';

const styles = () => ({
  root: {
    background:
      "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
    backgroundSize: 'cover',
  },
  intro: {
    color: '#ffffff',
  },
  card: {
    width: '100%',
    maxWidth: 400,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          'flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0'
        )}
      >
        <div
          className={classNames(
            classes.intro,

            `flex flex-col flex-no-grow items-center p-16 text-center md:p-128 md:ite
             ms-start md:flex-no-shrink md:flex-1 md:text-left`
          )}
        >
          <FuseAnimate animation="transition.expandIn">
            <img
              className="w-128 mb-32"
              src="assets/images/logos/smartek.svg"
              alt="logo"
            />
          </FuseAnimate>

          <FuseAnimate animation="transition.slideUpIn" delay={300}>
            <Typography variant="h3" color="inherit" className="font-light">
              Bem vindo à SMARTEK LIFE!
            </Typography>
          </FuseAnimate>

          <FuseAnimate delay={400}>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="max-w-512 mt-16"
            >
              Todos os controles em seu celular, sua casa na palma da sua mão
              onde quer que você esteja.
            </Typography>
          </FuseAnimate>
        </div>

        <FuseAnimate
          animation={{ translateX: [0, '100%'] }}
          style={{ display: 'flex' }}
        >
          <Card className={classNames(classes.card, 'mx-auto m-16 md:m-0')}>
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: '30px',
              }}
            >
              <Typography variant="h6" className="text-center md:w-full mb-48">
                ACESSE SUA CONTA
              </Typography>
              <JWTLoginTab />
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
