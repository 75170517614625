/* eslint-disable default-param-last */
import { fuseNavigationConfig } from '../../../fuse-configs/fuseNavigationConfig';
import * as Actions from '../../actions/fuse/index';

const initialState = fuseNavigationConfig;

// eslint-disable-next-line func-names
const navigation = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_NAVIGATION: {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};

export default navigation;
