import { services } from '../../../../api/feathers';

export const GET_IR_CONTROLS = 'GET_IR_CONTROLS';
export const GET_IR_CONTROL = 'GET_IR_CONTROL';
export const ADD_IR_CONTROLS = 'ADD_IR_CONTROLS';
export const UPDATE_IR_CONTROLS = 'UPDATE_IR_CONTROLS';
export const REMOVE_IR_CONTROLS = 'REMOVE_IR_CONTROLS';

export function getIrControls(deviceId) {
  return (dispatch) =>
    services.irControls
      .find({
        query: {
          deviceId,
          $sort: {
            createdAt: 1,
          },
        },
      })
      .then((response) =>
        dispatch({
          type: GET_IR_CONTROLS,
          payload: response.data,
        })
      );
}

export function getIrControl(id) {
  return (dispatch) =>
    services.irControls.get(id).then((response) =>
      dispatch({
        type: GET_IR_CONTROL,
        payload: response,
      })
    );
}

export function addIrControls(irControl) {
  return (dispatch) =>
    services.irControls
      .create(irControl)
      .then((response) =>
        dispatch({
          type: ADD_IR_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getIrControls(irControl.deviceId)));
}

export function updateIrControls(id, irControl) {
  return (dispatch) =>
    services.irControls
      .patch(id, irControl)
      .then((response) =>
        dispatch({
          type: UPDATE_IR_CONTROLS,
          payload: response,
        })
      );
}

export function removeIrControls(id) {
  return (dispatch) =>
    services.irControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_IR_CONTROLS,
        payload: response,
      })
    );
}
