/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment, no-useless-constructor */
/* eslint-disable react/jsx-no-useless-fragment, react/jsx-fragments */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import jwtService from '../jwtService';
import { tokenValidate } from './store/actions/login.actions';
import { setUserData, logoutUser } from './store/actions/user.actions';
import {
  hideMessage,
  showMessage,
} from '../store/actions/fuse/message.actions';

class Auth extends Component {
  constructor(props) {
    super(props);

    // this.jwtCheck();

    /**
     * Login with Auth0
     */
    // this.auth0Check();

    /**
     * Login with Firebase
     */
    // this.firebaseCheck();
  }

  // async componentDidMount() {
  //   // eslint-disable-next-line no-shadow
  //   const { auth, tokenValidate } = this.props;
  //   const { login } = auth || {};
  //   const { verifyed } = login || {};
  //   if (!verifyed) {
  //     await tokenValidate();
  //   }
  // }

  async UNSAFE_componentWillMount()
  {
    const { auth, location, authTokenValidate } = this.props;
    const { login } = auth || {};
    const { verifyed } = login || {};
    const { pathname } = location || {}
    if (pathname !== '/login' && !pathname.includes('/recoverPassword')){
      if (!verifyed) {
          await authTokenValidate();
      }
    }
  }

  /* eslint-disable-next-line */
  jwtCheck = () => {
    jwtService.on('onAutoLogin', () => {
      this.props.showMessage({ message: 'Logging in with JWT' });

      /**
       * Sign in and retrieve user data from Api
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          this.props.setUserData(user);

          this.props.showMessage({ message: 'Logged in with JWT' });
        })
        .catch((error) => {
          this.props.showMessage({ message: error });
        });
    });

    jwtService.on('onAutoLogout', (message) => {
      if (message) {
        this.props.showMessage({ message });
      }
      this.props.logout();
    });
  };

    render()
    {
        const {children, auth, location} = this.props;
        const { login } = auth || {};
        const { verifyed } = login || {};
        const { pathname } = location || {}

        if (pathname === '/login' || pathname.includes('/recoverPassword')) {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            );
        }

        if (!verifyed) {
          return <div>Aguarde...</div>
        }
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
      authTokenValidate: tokenValidate,
    },
    dispatch
  );
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps({ fuse, auth }) {
  return {
    auth,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
