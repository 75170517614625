import * as Actions from '../actions/channelImages.actions';

const initialState = [];

const channelImages = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CHANNEL_IMAGES:
      return action.payload;

    case Actions.ADD_CHANNEL_IMAGES:
      return [...state, action.payload];

    case Actions.UPDATE_CHANNEL_IMAGES:
    case Actions.GET_CHANNEL_IMAGE: {
      const index = state.findIndex(
        (channelImage) => channelImage.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    default:
      return state;
  }
};

export default channelImages;
