import * as Actions from '../actions/shortcuts.actions';

const initialState = [];

const shortcuts = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.GET_SHORTCUTS:{
      return action.payload;
    }
    case Actions.ADD_SHORTCUTS:{
      return [...state, action.payload];
    }
    case Actions.UPDATE_SHORTCUTS:
    case Actions.GET_SHORTCUT:{
      const index = state.findIndex(
        (shortcut) => shortcut.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_SHORTCUTS:{
      const removeState = state.filter(
        (shortcut) => shortcut.id !== action.payload.id
      );
      return [...removeState];
    }
    default:
      return state;
  }
};

export default shortcuts;
