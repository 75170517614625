import * as Actions from '../actions/ledControls.actions';

const initialState = [];

const ledControls = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_LED_CONTROLS:
      return action.payload;

    case Actions.ADD_LED_CONTROLS:
      return [...state, action.payload];

    case Actions.UPDATE_LED_CONTROLS:
    case Actions.GET_LED_CONTROL:{
      const index = state.findIndex(
        (ledControl) => ledControl.id == action.payload.id
      );
      
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...state];
    }
    case Actions.REMOVE_LED_CONTROLS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default ledControls;
