import * as Actions from '../actions/accountsUsers.actions';

const initialState = [];

const accountsUsers = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ACCOUNTS_USERS:
      return action.payload;

    case Actions.ADD_ACCOUNTS_USERS:
      return [...state, action.payload];

    case Actions.UPDATE_ACCOUNTS_USERS:
    case Actions.GET_ACCOUNT_USER: {
      const index = state.findIndex(
        (accountUser) => accountUser.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }
    case Actions.REMOVE_ACCOUNTS_USERS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default accountsUsers;
