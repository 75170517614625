import React, { Component } from 'react';
import Formsy from 'formsy-react';
import {
  withStyles,
  Button,
  InputAdornment,
  Icon,
  Box,
  IconButton,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { TextFieldFormsy } from '../../../../@fuse';
import * as Actions from '../../../../auth/store/actions';
import history from '../../../../history';
import '../../../../styles/customizations.css';
import packageJson from '../../../../../package.json';

const styles = () => ({
  root: {
    width: '100%',
  },
});

class JWTLoginTab extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      validationErrors: null,
      showPassword: false,
    };
  }

  componentDidUpdate() {
    const { login, clearLoginError } = this.props;

    if (login.error && (login.error.email || login.error.password)) {
      this.setState({
        validationErrors: 'Usuário ou senha inválida!',
      });

      clearLoginError(); // Usando a prop clearLoginError
    }

    if (login.success) {
      history.push({
        pathname: '/account/list',
      });
    }
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  onSubmit = (model) => {
    const { submitLogin } = this.props;
    const { email, password } = model;

    if (!email) {
      this.form.updateInputsWithError({
        email: 'Informe o usuário!',
      });
      console.log('OnSubmit: No email provided');
      return false;
    }

    if (!password) {
      this.form.updateInputsWithError({
        password: 'Informe a senha!',
      });
      console.log('OnSubmit: No password provided');
      return false;
    }

    submitLogin(model);
    return true;
  };

  handleChange = (event) => {
    if (event && event.target) {
      console.log(
        'HandleChange: Target name and value',
        event.target.name,
        event.target.value
      );
      this.setState({ validationErrors: null });
    } else if (event && event.name) {
      console.log('HandleChange: Direct input', event.name, event.value);
      this.setState({ validationErrors: null });
    } else {
      console.warn('HandleChange: Event or event.target is undefined');
    }
  };

  render() {
    const { classes } = this.props;
    const { validationErrors, showPassword } = this.state;
    const { version } = packageJson;
    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onChange={this.handleChange}
          ref={(form) => {
            this.form = form;
            console.log('Render: Form reference set');
          }}
          className="flex flex-col justify-center w-full"
        >
          <TextFieldFormsy
            className="login-input"
            type="text"
            name="email"
            label="Username/Email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            required
            style={{ marginBottom: '15px' }}
            onChange={this.handleChange}
          />

          <TextFieldFormsy
            className="login-input"
            type={showPassword ? 'text' : 'password'}
            name="password"
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.togglePasswordVisibility}
                  >
                    <Icon className="text-20" color="action">
                      {showPassword ? 'visibility' : 'visibility_off'}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            required
            onChange={this.handleChange}
          />
          {validationErrors && (
            <div style={{ color: 'red' }}>{validationErrors}</div>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-full mx-auto mt-16 normal-case"
            aria-label="LOG IN"
            style={{ marginTop: '10px', marginBottom: '10px' }}
            value="legacy"
          >
            Login
          </Button>
          <a
            style={{ marginTop: '10px', marginBottom: '10px' }}
            href="https://api.smarteklife.com/oauth/amazon"
            target="_blank"
            rel="noreferrer"
          >
            Login Amazon
          </a>
        </Formsy>
        <Box style={{ marginTop: 50, textAlign: 'center' }}>
          <span style={{ fontSize: '12px' }}>Versão: {version}</span>
        </Box>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLogin: Actions.submitLogin,
      clearLoginError: () => ({ type: Actions.LOGIN_ERROR, payload: null }), // Ação fictícia para limpar o erro
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(JWTLoginTab))
);
