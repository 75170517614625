import * as Actions from '../actions/irControls.actions';

const initialState = [];

const irControls = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_IR_CONTROLS: {
      return action.payload;
    }
    case Actions.ADD_IR_CONTROLS: {
      return [...state, action.payload];
    }
    case Actions.UPDATE_IR_CONTROLS:
    case Actions.GET_IR_CONTROL: {
      const index = state.findIndex(
        (irControl) => irControl.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    case Actions.REMOVE_IR_CONTROLS: {
      return state.filter((obj) => obj.id !== action.payload.id);
    }

    default:
      return state;
  }
};

export default irControls;
